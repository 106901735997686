import classNames from "classnames";
import { SnowmobileFraResponse } from "../../../interfaces/backend/snowmobile-paths.interface";
import styles from "./fra-list.module.css";
import { FraListEntry } from "./fra-list-entry/fra-list-entry.component";
import { Component } from "react";
import AppContext from "../../context/app-context";
import { FeatureType } from "../../../enums/map.enums";

export class FraList extends Component {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  render() {
    const { mapSnowmobileFras } = this.context;
    return (
      <div className={styles.listContainer}>
        {Object.values(mapSnowmobileFras).map((fra, index) => (
          <div key={fra.id}>
            <FraListEntry
              className={classNames({
                [styles.evenEntry]: index % 2 === 0,
                [styles.oddEntry]: index % 2 === 1,
              })}
              name={fra.name}
              onClick={() => this.handleOnClick(fra.id.toString())}
            />
          </div>
        ))}
      </div>
    );
  }
  handleOnClick(id: string) {
    this.context.setSelected(FeatureType.SnowmobileFra, id);
  }
}
