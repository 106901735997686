import { Component, FormEvent } from 'react';
import { MapType } from '../../enums/map.enums';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/map-settings.svg';
import { ReactComponent as GpsOnTargetIcon } from '../../assets/images/gps-on-target.svg';
import { ReactComponent as GpsOffTargetIcon } from '../../assets/images/gps-off-target.svg';
import AppContext from '../context/app-context';
import classNames from 'classnames';
import styles from './map-buttons.module.css';

type MapButtonsProps = {
    //
}

type MapButtonsState = {
    open: boolean;
}

class MapButtons extends Component<MapButtonsProps, MapButtonsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: MapButtonsProps) {
        super(props);

        this.state = {
            open: false,
        };
    }

    render() {
        const {mapType, gpsPosition, gpsCentered} = this.context;

        return (
            <div className={styles.mapButtons}>
                <div className={styles.buttonRow}>
                    { gpsCentered
                        ? (<GpsOnTargetIcon className={classNames({
                            [styles.button]: true,
                            [styles.disabled]: !gpsPosition,
                        })} onClick={() => this.handleRecenter()}/>)
                        : (<GpsOffTargetIcon className={classNames({
                            [styles.button]: true,
                            [styles.disabled]: !gpsPosition,
                        })} onClick={() => this.handleRecenter()}/>)
                    }
                </div>

                <div className={styles.buttonRow}>
                    <SettingsIcon className={styles.button} onClick={() => this.setOpened(true)}/>

                    <form id="map-settings" name="mapSettings" className={classNames({
                        [styles.menu]: true,
                        [styles.hidden]: !this.state.open,
                    })}>
                        <input type="radio" id="map-option" name="types" value={MapType.Map} checked={mapType === MapType.Map} onChange={(event) => this.handleOptionChange(event)}/>
                        <label htmlFor="map-option">Karta</label>

                        <input type="radio" id="satelite-option" name="types" value={MapType.Satelite} checked={mapType === MapType.Satelite} onChange={(event) => this.handleOptionChange(event)}/>
                        <label htmlFor="satelite-option">Satellit</label>

                        <CloseIcon className={styles.cross} onClick={() => this.setOpened(false)}/>
                    </form>
                </div>
            </div>
        );
    }

    handleOptionChange(event: FormEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;
        this.context.setMapType(value as MapType);
    }

    handleRecenter() {
        this.context.setGpsCentered(true);
    }

    setOpened(open: boolean) {
        this.setState({
            ...this.state,
            open,
        });
    }
}

export default MapButtons;