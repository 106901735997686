import { Component } from "react";
import { AppContentContext, FilterIdentifier } from "../../enums/app.enums";
import AppContext from "../context/app-context";
import styles from "./side-bar.module.css";
import PathList from "./path-list/path-list.component";
import SnowmobileSubPathList from "./snowmobile-subpath-list/snowmobile-subpath-list.component";
import Filters from "./filters/filters.component";
import { FraList } from "./fra-list/fra-list.component";

class SideBar extends Component {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  render() {
    const { appContentContext } = this.context;
    const excludedIdentifiers = this.getExcludedFilterIdentifiers();

    return (
      <div className={styles.content}>
        <Filters excludedIdentifiers={excludedIdentifiers} />
        <div className={styles.list}>
          {appContentContext === AppContentContext.Snowmobiling && (
            <SnowmobileSubPathList />
          )}
          {appContentContext === AppContentContext.Fra && <FraList />}
          {(appContentContext === AppContentContext.CrossCountrySkiing ||
            appContentContext === AppContentContext.MountainBiking ||
            appContentContext === AppContentContext.Hiking ||
            appContentContext === AppContentContext.WinterHiking ||
            appContentContext === AppContentContext.Rombo ||
            appContentContext === AppContentContext.Cultures) && <PathList />}
        </div>
      </div>
    );
  }

  getExcludedFilterIdentifiers(): FilterIdentifier[] {
    const { appContentContext } = this.context;

    switch (appContentContext) {
      case AppContentContext.Snowmobiling:
        return [FilterIdentifier.Length, FilterIdentifier.Difficulty];

      case AppContentContext.Hiking:
      case AppContentContext.MountainBiking:
      case AppContentContext.WinterHiking:
      case AppContentContext.Rombo:
      case AppContentContext.Cultures:
        return [FilterIdentifier.Status];

      default:
        return [];
    }
  }
}

export default SideBar;
