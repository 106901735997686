import { AppContentContext } from "../enums/app.enums";
import {
  PathCategoryResponse,
  PathResponse,
} from "../interfaces/backend/path.interface";
import {
  PoiCategoryResponse,
  PoiResponse,
} from "../interfaces/backend/poi.interface";
import {
  SnowmobileFraResponse,
  SnowmobilePathResponse,
  SnowmobileSubPathResponse,
} from "../interfaces/backend/snowmobile-paths.interface";
import { MapCategories } from "../interfaces/map/common.interfaces";
import {
  MapPathGpsCoordinates,
  MapPaths,
} from "../interfaces/map/path.interface";
import { MapPois } from "../interfaces/map/poi.interface";
import {
  MapSnowmobilePaths,
  MapSnowmobileSubPaths,
} from "../interfaces/map/snowmobile-path.interface";
import { Path } from "../interfaces/path.interface";
import { SnowmobileSubPath } from "../interfaces/snowmobile-path.interface";

/**
 * Converts the PathResponse array to a useable hashmap of paths formatted for the map
 */
export function toMapPaths(paths: PathResponse[]): MapPaths {
  const reduced = paths?.reduce(
    (acc, path) => ({
      ...acc,
      [`${path.id}`]: {
        id: `${path.id}`,
        name: path.name,
        number: path.number,
        pathCategoryId: `${path.pathCategoryId}`,
        status: path.status,
        ...(path.start && { start: path.start }),
        ...(path.end && { end: path.end }),
      },
    }),
    {}
  );

  return reduced as MapPaths;
}

/**
 * Converts the PathResponse array to a useable hashmap of GPS coordinates formatted for the map
 */
export function toMapPathGpsCoordinates(
  paths: PathResponse[]
): MapPathGpsCoordinates {
  const reduced = paths?.reduce(
    (acc, path) => ({
      ...acc,
      [`${path.id}`]: path.pathGpsCoordinates.map((coordinate: any) => ({
        longitude: coordinate.longitude,
        latitude: coordinate.latitude,
      })),
    }),
    {}
  );

  return reduced as MapPathGpsCoordinates;
}

/**
 * Converts both PathCategoryResponse array and PoiCategoryResponse array to a useable hashmap of categories formatted for the map
 */
export function toMapCategories(
  categories: PathCategoryResponse[] | PoiCategoryResponse[]
): MapCategories {
  const untyped = categories as any[];
  const reduced = untyped?.reduce(
    (acc, category) => ({
      ...acc,
      [`${category.id}`]: {
        id: `${category.id}`,
        identifier: category.identifier,
      },
    }),
    {}
  );

  return reduced as MapCategories;
}

/**
 * Converts the SnowmobilePathResponse array to a useable hashmap of snowmobile paths formatted for the map
 */
export function toMapSnowmobilePaths(
  paths: SnowmobilePathResponse[]
): MapSnowmobilePaths {
  const reduced = paths?.reduce(
    (acc, path) => ({
      ...acc,
      [`${path.id}`]: {
        id: `${path.id}`,
        subPaths: path.subPaths.map((subPath) => `${subPath.id}`),
      },
    }),
    {}
  );

  return reduced as MapSnowmobilePaths;
}

/**
 * Converts the SnowmobileSubPathResponse array to a useable hashmap of snowmobile subpaths formatted for the map
 */
export function toMapSnowmobileSubPaths(
  paths: SnowmobileSubPathResponse[]
): MapSnowmobileSubPaths {
  const reduced = paths?.reduce(
    (acc, path) => ({
      ...acc,
      [`${path.id}`]: {
        id: `${path.id}`,
        status: path.status,
        subPathGPSCoordinates: path.subPathGPSCoordinates.map(
          (gpsCoordinates) => ({
            longitude: gpsCoordinates.longitude,
            latitude: gpsCoordinates.latitude,
          })
        ),
        ...(path.start && { start: path.start }),
        ...(path.end && { end: path.end }),
      },
    }),
    {}
  );

  return reduced as MapSnowmobileSubPaths;
}

/**
 * Converts the PoiResponse array to a useable hashmap of pois formatted for the map
 */
export function toMapPois(pois: PoiResponse[]): MapPois {
  const reduced = pois?.reduce(
    (acc, poi) => ({
      ...acc,
      [`${poi.id}`]: {
        id: `${poi.id}`,
        name: poi.name,
        longitude: poi.longitude,
        latitude: poi.latitude,
        poiCategoryId: poi.pointOfInterestCategoryId,
      },
    }),
    {}
  );

  return reduced as MapPois;
}

/**
 * Converts the PathResponse array to a useable hashmap of paths
 */
export function toPaths(paths: PathResponse[]): { [key: string]: Path } {
  const reduced = paths?.reduce(
    (acc, path) => ({
      ...acc,
      [`${path.id}`]: {
        ...path,
        id: `${path.id}`,
        pathCategoryId: `${path.pathCategoryId}`,
        tags: path.tags.map((tag) => tag.name),
        areas: path.areas.map((area) => `${area.name.toLowerCase()}`),
      },
    }),
    {}
  );

  return reduced as { [key: string]: Path };
}

/**
 * Converts the SnowmobileSubPathResponse array to a useable hashmap of snowmobile subpaths
 */
export function toSnowmobileSubPaths(subPaths: SnowmobileSubPathResponse[]): {
  [key: string]: SnowmobileSubPath;
} {
  const reduced = subPaths?.reduce(
    (acc, subPath) => ({
      ...acc,
      [`${subPath.id}`]: {
        ...subPath,
        id: `${subPath.id}`,
        subPathGPSCoordinates: subPath.subPathGPSCoordinates.map(
          (coordinate) => ({
            longitude: coordinate.longitude,
            latitude: coordinate.latitude,
            elevation: coordinate.elevation,
            time: coordinate.time,
          })
        ),
        areas: subPath.areas.map((area) => `${area.name.toLowerCase()}`),
        areasRaw: subPath.areas,
      },
    }),
    {}
  );

  return reduced as { [key: string]: SnowmobileSubPath };
}

export function toSnowmobilFra(fras: SnowmobileFraResponse[]): {
  [key: string]: SnowmobileFraResponse;
} {
  const reduced = fras?.reduce(
    (acc, fra) => ({
      ...acc,
      [`${fra.id}`]: {
        ...fra,
      },
    }),
    {}
  );

  return reduced as { [key: string]: SnowmobileFraResponse };
}

/**
 * Converts the numeric length to a presentable kilometer string
 */
export function toKilometerString(length: number): string {
  return `${length.toFixed(1).replace(".", ",")} km`;
}

export function contextToPathCategoryId(context: AppContentContext) {
  switch (context) {
    case AppContentContext.CrossCountrySkiing:
      return 1;

    case AppContentContext.Snowmobiling ||
      AppContentContext.Fra ||
      AppContentContext.SnowmobilingSubPath:
      return 2;

    case AppContentContext.MountainBiking:
      return 3;

    case AppContentContext.Hiking:
      return 4;

    case AppContentContext.WinterHiking:
      return 5;

    case AppContentContext.Rombo:
      return 6;

    case AppContentContext.Cultures:
      return 7;
  }
}
