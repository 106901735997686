import { Component, ReactNode } from "react";
import AppContext from "../context/app-context";
import { getSinglePathData } from "../../utils/api.utls";
import {
  PathCategoryResponse,
  PathGpsCoordinateResponse,
  PathTagResponse,
} from "../../interfaces/backend/path.interface";
import styles from "./path-info.module.css";
import {
  getPartialPathIcon,
  getPathColor,
  getPathDifficultyIcon,
} from "../../utils/rendering.utils";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { AppContentContext } from "../../enums/app.enums";
import { FeatureType } from "../../enums/map.enums";

type PathInfoProps = {
  isStandAlone: boolean;
};

interface PathInfoState {
  id: string | undefined;
  name: string;
  number: string;
  length: number;
  difficulty: string;
  status: string;
  description: string;
  end: string;
  start: string;
  description_en: string;
  pathGpsCoordinates: PathGpsCoordinateResponse[];
  pathCategoryId: number | undefined;
  tags: PathTagResponse[];
  tagsRaw: any[];
  pathCategory?: PathCategoryResponse;
  isVisible: boolean;
}

class PathInfo extends Component<PathInfoProps, PathInfoState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  private reversed = false;
  constructor(props: PathInfoProps) {
    super(props);
    this.state = {
      id: undefined,
      name: "",
      number: "",
      length: 0,
      difficulty: "",
      status: "",
      description: "",
      end: "",
      start: "",
      description_en: "",
      pathGpsCoordinates: [],
      pathCategoryId: undefined,
      tags: [],
      tagsRaw: [],
      pathCategory: undefined,
      isVisible: true,
    };
  }

  componentDidMount(): void {
    const { renderedInApp, appContentContext, highlightedType, selectedType } =
      this.context;

    const isSnowMobiling =
      appContentContext?.toString() ===
        AppContentContext.Snowmobiling.toString() ||
      highlightedType === AppContentContext.SnowmobilingSubPath.toString();

    const isFra =
      selectedType === AppContentContext.Fra.toString() ||
      highlightedType === FeatureType.SnowmobileFra;
    const shouldRender = this.props.isStandAlone || renderedInApp;
    if (
      this.context.highlightedId &&
      shouldRender &&
      !isSnowMobiling &&
      !isFra
    ) {
      this.fetchData();
      this.show();
    }
  }
  componentDidUpdate(
    prevProps: Readonly<PathInfoProps>,
    prevState: Readonly<PathInfoState>,
    snapshot?: any
  ): void {
    const { renderedInApp, appContentContext, selectedType, highlightedType } = this.context;

    const isSnowMobiling =
      appContentContext === AppContentContext.Snowmobiling.toString() ||
      selectedType === AppContentContext.SnowmobilingSubPath.toString();
    const shouldRender = this.props.isStandAlone || renderedInApp;

    const isFra =
      selectedType === AppContentContext.Fra.toString() ||
      appContentContext === AppContentContext.Fra.toString() ||
      selectedType === AppContentContext.Fra.toString() ||
      highlightedType === FeatureType.SnowmobileFra;

    if (
      this.context.highlightedId !== this.state.id &&
      shouldRender &&
      !isSnowMobiling &&
      !isFra
    ) {
      this.fetchData();
      this.show();
    }
  }

  hide() {
    this.setState({ isVisible: false });
  }

  show() {
    this.setState({ isVisible: true });
  }

  difficultyMappings(difficulty: string) {
    switch (difficulty) {
      case "veryEasy":
        return "Mycket lätt";
      case "easy":
        return "Lätt";
      case "medium":
        return "Medelsvår";
      case "hard":
        return "Svår";

      default:
        return difficulty;
    }
  }
  getMaxHeight() {
    const pathGPSCoords = this.state.pathGpsCoordinates;
    let max = 0;
    for (let i = 0; i < pathGPSCoords?.length; i++) {
      if (pathGPSCoords[i].elevation > max) {
        max = pathGPSCoords[i].elevation;
      }
    }
    return Math.round(max);
  }

  getMinHeight() {
    const pathGPSCoords = this.state.pathGpsCoordinates;
    let min = 7000;
    for (let i = 0; i < pathGPSCoords?.length; i++) {
      if (pathGPSCoords[i].elevation < min) {
        min = pathGPSCoords[i].elevation;
      }
    }
    return Math.round(min);
  }
  reverseIndex(i: number) {
    if (this.reversed) {
      return this.state.pathGpsCoordinates.length - 1 - i;
    }
    return i;
  }

  getDistanceToStart(i: number) {
    const pathGPSCoords = this.state.pathGpsCoordinates;
    if (i === 0 && this.reversed) {
      return this.getTotalDistance();
    }
    if (i === 0) {
      return 0.0;
    }
    if (this.reversed) {
      i = this.reverseIndex(i);
    }

    const p = 0.017453292519943295;
    const c = Math.cos;
    let distanceToStart = 0;

    for (i; i > 1; i--) {
      const lat1 = pathGPSCoords[i].latitude;
      const lat2 = pathGPSCoords[i - 1].latitude;
      const lon1 = pathGPSCoords[i].longitude;
      const lon2 = pathGPSCoords[i - 1].longitude;

      const a =
        0.5 -
        c((lat2 - lat1) * p) / 2 +
        (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

      distanceToStart += 12742 * Math.asin(Math.sqrt(a));
    }
    return distanceToStart;
  }

  getTotalElevation() {
    const pathGPSCoords = this.state.pathGpsCoordinates;
    let max = 0;
    let min = 7000;
    for (let i = 0; i < pathGPSCoords?.length; i++) {
      if (pathGPSCoords[i].elevation < min) {
        min = pathGPSCoords[i].elevation;
      }
      if (pathGPSCoords[i].elevation > max) {
        max = pathGPSCoords[i].elevation;
      }
    }
    const maxElevation = Math.round(max * 10) / 10;
    const minElevation = Math.round(min * 10) / 10;
    return Math.round(((maxElevation - minElevation) * 10) / 10);
  }

  getTotalDistance() {
    const pathGPSCoords = this.state.pathGpsCoordinates;
    let totalDistance = 0;
    const p = 0.017453292519943295;
    const c = Math.cos;
    for (let i = 1; i < pathGPSCoords?.length; i++) {
      const lat1 = pathGPSCoords[i - 1].latitude;
      const lat2 = pathGPSCoords[i].latitude;
      const lon1 = pathGPSCoords[i - 1].longitude;
      const lon2 = pathGPSCoords[i].longitude;

      const a =
        0.5 -
        c((lat2 - lat1) * p) / 2 +
        (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

      totalDistance += 12742 * Math.asin(Math.sqrt(a));
    }
    return totalDistance;
  }

  fetchData() {
    const { highlightedId } = this.context;

    Promise.resolve(getSinglePathData("paths", highlightedId)).then((path) => {
      this.setState({
        name: path.name,
        number: path.number,
        length: path.length,
        difficulty: path.difficulty,
        description: path.description,
        description_en: path.description_en,
        end: path.end,
        start: path.start,
        pathGpsCoordinates: path.pathGpsCoordinates,
        pathCategoryId: path.pathCategoryId,
        tags: path.tags,
        tagsRaw: path.tagsRaw,
        pathCategory: path.pathCategory,
        status: path.status,
      });
    });
    this.setState({ id: this.context.highlightedId });
  }

  getLineHeight(min: any, max: any, tot: any) {
    if (max === 0 || min === 0 || tot === 0) {
      return 0;
    }
    return (max - min) * ((100 / (tot + 100)) * 14);
  }

  getTextHeight(textHeight: number) {
    return textHeight + " " + "m.ö.h";
  }

  replaceWithBr() {
    return this.state.description?.replace(/\n/g, "<br />");
  }

  formatLength(length: number) {
    return `${length.toFixed(1).replace(".", ",")} km`;
  }

  render(): ReactNode {
    const { mapPathCategories, highlightedType, highlightedId } = this.context;

    if (
      highlightedId &&
      highlightedType &&
      highlightedType !== FeatureType.Poi &&
      this.state.pathCategoryId &&
      this.state.isVisible
    ) {
      const type = mapPathCategories[`${this.state.pathCategoryId}`].identifier;
      const Icon = (props: any) => getPartialPathIcon(type);
      const color = getPathColor(type);
      const DifficultyIcon = (props: any) =>
        getPathDifficultyIcon(this.state.difficulty, props);

      const renderIcon = () => (
        <>
          <Icon className={styles.icon} style={{ color: color }} />
          <span
            className={isStandAlone ? styles.iconText : styles.iconTextMobile}
          >
            {this.state.number}
          </span>
        </>
      );

      const isStandAlone = this.props.isStandAlone && window.innerWidth > 768;
      const totalDistance = this.getTotalDistance();
      const totalElevation = this.getTotalElevation();

      const profileWidth = 7000;
      const profileHeight = 1800;
      const testMaxHeight = this.getMaxHeight();
      const testMinHeight = this.getMinHeight();
      const testTotalElevation = this.getTotalElevation();
      const lineHeight = this.getLineHeight(
        testMinHeight,
        testMaxHeight,
        testTotalElevation
      );

      const textMaxHeight = this.getTextHeight(testMaxHeight);
      const textMinHeight = this.getTextHeight(testMinHeight);

      const difficultyName = this.difficultyMappings(this.state.difficulty);
      const metadata =
        this.state.tags && this.state.tags.length
          ? `${difficultyName}, ${this.state.tags
              .map((tag) => tag.name)
              .join(", ")}`
          : difficultyName;

      const statusText = this.state.status === "closed" ? "Stängt" : "";
      const statusColor =
        this.state.status === "closed" ? "#bd2c16" : undefined;

      return (
        <div
          className={
            isStandAlone
              ? styles.pathInfoContainerStandAlone
              : styles.pathInfoContainer
          }
        >
          <div className={styles.header}>
            <div
              style={{ color: color }}
              className={
                isStandAlone ? styles.iconContainer : styles.iconContainerMobile
              }
            >
              {renderIcon()}
            </div>
            <div className={styles.headerRightCol}>
              <h1 className={isStandAlone ? styles.title : styles.titleMobile}>
                {this.state.name}
              </h1>
              <div className={styles.difficultyRow}>
                <DifficultyIcon
                  className={
                    isStandAlone
                      ? styles.difficultyIcon
                      : styles.difficultyIconMobile
                  }
                />
                <div className={styles.difficultyText}>{metadata}</div>
              </div>
              <div className={styles.statusRow}>
                <div className={styles.length}>
                  {this.formatLength(this.state.length)}
                </div>
                <div className={styles.statusText}>
                  {this.state.pathCategory?.name}
                </div>
                {statusText.length > 0 && (
                  <>
                    <div className={styles.statusText}>-</div>
                    <div
                      className={styles.statusText}
                      style={{ color: statusColor }}
                    >
                      {statusText}
                    </div>
                  </>
                )}
              </div>
            </div>
            <CloseIcon
              className={styles.closeButton}
              onClick={() => this.hide()}
            />
          </div>

          <div
            style={{
              flex: 1,
              height: isStandAlone ? 200 : 110,
              marginBottom: !isStandAlone ? 0 : 5,
              marginLeft: !isStandAlone ? 16 : 0,
              marginRight: !isStandAlone ? 16 : 0,
            }}
          >
            <svg
              height={isStandAlone ? "200px" : "110px"}
              width={isStandAlone ? "100%" : "100%"}
              preserveAspectRatio="none"
              viewBox={`0 0 ${profileWidth} ${profileHeight}`}
              style={{ backgroundColor: "#D1E3EC" }}
            >
              <polyline
                fill="#82A996"
                stroke="#82A996"
                strokeWidth="3"
                points={`${this.state.pathGpsCoordinates?.map(
                  (coord, index) =>
                    `${
                      (profileWidth / this.state.pathGpsCoordinates.length +
                        profileWidth /
                          this.state.pathGpsCoordinates.length /
                          this.state.pathGpsCoordinates.length) *
                      index
                    }, 
                    ${
                      profileHeight -
                      350 -
                      (coord.elevation - testMinHeight) *
                        ((100 / (testTotalElevation + 100)) * 14)
                    }`
                )},
                  ${profileWidth}, ${profileHeight}, 0, ${profileHeight}`}
              />

              <line
                x1={0}
                y1={profileHeight - 350 - lineHeight}
                x2={profileWidth}
                y2={profileHeight - 350 - lineHeight}
                fill="rgba(153, 153, 153, .5)"
                stroke="rgba(153, 153, 153, .5)"
                strokeWidth="30"
                strokeLinecap="round"
                strokeDasharray="1, 80"
              />

              <text
                fill="#999999"
                stroke="#999999"
                strokeWidth="8"
                fontSize="215"
                fontStretch="normal"
                x={profileWidth - 40}
                y={profileHeight - 400 - lineHeight}
                textAnchor="end"
              >
                {textMaxHeight}
              </text>

              <line
                x1={0}
                y1={profileHeight - 350}
                x2={profileWidth}
                y2={profileHeight - 350}
                fill="rgba(255, 255, 255, .5)"
                stroke="rgba(255, 255, 255, .5)"
                strokeWidth="30"
                strokeLinecap="round"
                strokeDasharray="1, 80"
              />

              <text
                fill="#FFFFFF"
                stroke="#FFFFFF"
                strokeWidth="8"
                fontSize="215"
                fontStretch="normal"
                x={profileWidth - 40}
                y={profileHeight - 100}
                textAnchor="end"
              >
                {textMinHeight}
              </text>
            </svg>
          </div>
          <div className={styles.descriptionContainer}>
            <div className={styles.elevationText}>
              {totalElevation} Höjdmeter
            </div>
            <div
              className={styles.descriptionText}
              dangerouslySetInnerHTML={{ __html: this.replaceWithBr() }}
            />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default PathInfo;
