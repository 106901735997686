import classNames from "classnames";
import React, { Component, RefObject } from "react";
import { AppContentContext } from "../../../enums/app.enums";
import { FeatureType } from "../../../enums/map.enums";
import { Path } from "../../../interfaces/path.interface";
import AppContext from "../../context/app-context";
import PathListEntry from "./path-list-enttry/path-list-entry.component";
import styles from './path-list.module.css';

class PathList extends Component {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    listRef!: RefObject<HTMLDivElement>;
    entryRefs!: {[key: string]: RefObject<HTMLDivElement>};
    previousPaths?: Path[];
    previousHighlightedType?: FeatureType;
    previousHighlightedId?: string;

    componentDidMount() {
        this.listRef = React.createRef()
        this.updateRefs();
    }

    componentDidUpdate() {
        const {highlightedType, highlightedId} = this.context;
        this.updateRefs();

        if (highlightedId !== this.previousHighlightedId) {
            this.previousHighlightedId = highlightedId;

            if (highlightedType === FeatureType.Path && highlightedId !== undefined) {
                const ref = this.entryRefs[highlightedId];

                if (ref?.current && this.listRef.current) {
                    const entryTop = ref.current.offsetTop;
                    const listTop = this.listRef.current.offsetTop;

                    this.listRef.current.scrollTo({
                        top: entryTop - listTop,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }

    render() {
        const {filteredPaths, mapPathCategories, highlightedType, highlightedId} = this.context;
        
        // TODO: We should handle this on the backend in the future,
        // but since the paths are mapped into a object we lose any initially provided order.
        // Perhaps we should have a map to look up the index of each ID instead so we'll still have the original array intact.

        const compareNumber = (a: Path, b: Path) => {
            if (a.status === b.status) {
              if (Number.isNaN(parseInt(a.number)) && !Number.isNaN(parseInt(b.number))) {
                return 1;
              }
              if (!Number.isNaN(parseInt(a.number)) && Number.isNaN(parseInt(b.number))) {
                return -1;
              }
              if ((parseInt(a.number) || 0) > parseInt(b.number) || 0) {
                return 1;
              } else {
                return -1;
              }
            } else {
              if (b.status > a.status) {
                return 1;
              } else {
                return -1;
              }
            }
          };
        const sortedPaths = filteredPaths.sort(compareNumber)

        return (
            <div className={styles.listContainer} ref={this.listRef}>
                { sortedPaths.map((path, index) => this.entryRefs && (
                    <div key={path.id} ref={this.entryRefs[path.id]}>
                        <PathListEntry
                            className={classNames({
                                [styles.evenEntry]: index % 2 === 0,
                                [styles.oddEntry]: index % 2 === 1,
                                [styles.highlightedEntry]: highlightedType === FeatureType.Path && highlightedId === path.id,
                            })}
                            type={mapPathCategories[`${path.pathCategoryId}`].identifier}
                            name={path.name}
                            number={path.number}
                            difficulty={path.difficulty}
                            status={path.status}
                            length={path.length}
                            tags={path.tags}
                            groomedAt={path.groomedAt}
                            onClick={() => this.handleOnClick(path.id)}
                        />
                    </div>
                ))}
                { filteredPaths.length === 0 && <div className={styles.empty}>
                    { this.getEmptyText() }
                </div>}
            </div>
        );
    }

    updateRefs() {
        const {filteredPaths} = this.context;
        if (JSON.stringify(filteredPaths) !== JSON.stringify(this.previousPaths)) {
            this.entryRefs = filteredPaths.reduce((acc, curr) => ({
                ...acc,
                [curr.id]: React.createRef(), 
            }), {} as {[key: string]: RefObject<HTMLDivElement>});

            this.previousPaths = filteredPaths;
        }
    }

    handleOnClick(id: string) {
        this.context.setSelected(FeatureType.Path, id);
    }

    getEmptyText(): string {
        const {appContentContext} = this.context;

        switch(appContentContext) {
            case AppContentContext.CrossCountrySkiing:
                return 'Inga längdskidspår hittades';

            case AppContentContext.MountainBiking:
                return 'Inga cykelleder hittades';

            case AppContentContext.Hiking:
                return 'Inga vandringsspår hittades';
                
            case AppContentContext.WinterHiking:
                return 'Inga vintervandringsspår hittades';

            case AppContentContext.Rombo:
                return 'Inga Romboleder hittades';

            case AppContentContext.Cultures:
                return 'Inga kulturleder hittades';

            default:
                return '';
        }
    }
}

export default PathList;