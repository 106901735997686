export enum PathStatus {
  Open = "open",
  Closed = "closed",
}

export enum FeatureType {
  Path = "path",
  Poi = "poi",
  SnowmobilePath = "snowmobile-path",
  SnowmobileSubPath = "snowmobile-subpath",
  SnowmobileFra = "snowmobile-fra",
}

export enum MapType {
  Map = "map",
  Satelite = "satelite",
}

export enum PathCategoryId {
  CrossCountrySkiing = "1",
  Snowmobiling = "2",
  MountainBiking = "3",
  Hiking = "4",
  WinterHiking = "5",
  Rombo = "6",
  Cultures = "7",
}
