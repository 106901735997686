import moment from "moment";
import { PathAreaFilter, PathDifficulty, PathLengthFilter, PathStatusFilter } from "../enums/app.enums";
import { PathStatus } from "../enums/map.enums";

export function passesLengthFilter(length: number, condition?: PathLengthFilter): boolean {
    switch(condition) {
        case PathLengthFilter.ZeroToThree:
            return length <= 3;
        
        case PathLengthFilter.ThreeToSix:
            return length >= 3 && length <= 6;

        case PathLengthFilter.SixToTen:
            return length >= 6 && length <= 10;

        case PathLengthFilter.TenPlus:
            return length >= 10;

        default:
            return true;
    }
}

export function passesStatusFilter(status: string, groomedAt?: string, condition?: PathStatusFilter): boolean {
    const now = moment();
    const date = moment(groomedAt);

    switch (status) {
        case PathStatus.Closed:
            return condition === undefined;
        
        default:
            switch (condition) {
                case PathStatusFilter.GroomedToday:
                return date.isSame(now.clone().startOf('day'), 'd');

                case PathStatusFilter.GroomedYesterdayAtMost:
                return date.isSameOrAfter(now.clone().subtract(1, 'days').startOf('day'), 'd');

                default:
                    return true;
            }
    }
}

export function passesDiffidultyFilter(difficulty: string, condition?: PathDifficulty): boolean {
    return condition === undefined || difficulty === condition;
}

export function passesAreaFilter(areas: string[], condition?: PathAreaFilter): boolean {
    return condition === undefined || areas.includes(condition);
}