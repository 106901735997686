import {
  PathsRequirements,
  PoiRequirements,
  SnowmobileRequirements,
} from "../interfaces/api.interfaces";
import {
  PathCategoryResponse,
  PathResponse,
  SinglePathResponse,
} from "../interfaces/backend/path.interface";
import {
  PoiCategoryResponse,
  PoiResponse,
} from "../interfaces/backend/poi.interface";
import {
  SnowmobileFraResponse,
  SnowmobilePathResponse,
  SnowmobileSubPathResponse,
} from "../interfaces/backend/snowmobile-paths.interface";

export function getJson<T>(endpoint: string): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`)
      .then((res) => res.json())
      .then((json) => resolve(json))
      .catch((error) => reject(error));
  });
}

/**
 * Fetches all the required data to render paths
 */
export function getPathData(category?: string): Promise<PathsRequirements> {
  return new Promise<PathsRequirements>((resolve, reject) => {
    Promise.all([
      getJson<PathResponse[]>(
        category ? `/paths?filter={"pathCategoryId":"${category}"}` : "/paths"
      ),
      getJson<PathCategoryResponse[]>("/paths/categories"),
      getJson<PathResponse[]>(
        category
          ? `/paths/gps?filter={"pathCategoryId":"${category}"}`
          : "/paths/gps"
      ),
    ])
      .then(
        (
          responses: [PathResponse[], PathCategoryResponse[], PathResponse[]]
        ) => {
          const [paths, categories, gpsCoordinates] = responses;

          resolve({
            paths,
            categories,
            gpsCoordinates,
          });
        }
      )
      .catch((error) => reject(error));
  });
}

export function getSinglePathData(
  category?: string,
  id?: string
): Promise<SinglePathResponse> {
  return new Promise<SinglePathResponse>((resolve, reject) => {
    Promise.resolve(getJson<PathResponse>("/paths/" + id))
      .then((response: SinglePathResponse) => {
        const {
          name,
          number,
          length,
          difficulty,
          status,
          pathGpsCoordinates,
          description,
          description_en,
          start,
          end,
          pathCategoryId,
          tags,
          tagsRaw,
          pathCategory,
        } = response;

        resolve({
          name,
          number,
          length,
          difficulty,
          status,
          pathGpsCoordinates,
          description,
          description_en,
          start,
          end,
          pathCategoryId,
          tags,
          tagsRaw,
          pathCategory,
        });
      })
      .catch((error) => reject(error));
  });
}

export function getSnowmobileFraData(): Promise<{
  fras: SnowmobileFraResponse[];
}> {
  return new Promise<{ fras: SnowmobileFraResponse[] }>((resolve, reject) => {
    Promise.resolve(getJson<SnowmobileFraResponse[]>("/snowmobile/fras/gps"))
      .then((response: SnowmobileFraResponse[]) => {
        const fras: SnowmobileFraResponse[] = response.map((item) => {
          const newFra: SnowmobileFraResponse = {
            id: item.id,
            name: item.name,
            fraGpsCoordinates: item.fraGpsCoordinates,
          };
          return newFra;
        });

        resolve({ fras: fras });
      })
      .catch((error) => reject(error));
  });
}

/**
 * Fetches all the required data to render snowmobile paths
 */
export function getSnowmobileData(): Promise<SnowmobileRequirements> {
  return new Promise<SnowmobileRequirements>((resolve, reject) => {
    Promise.all([
      getJson<SnowmobilePathResponse[]>("/snowmobile/trails"),
      getJson<SnowmobileSubPathResponse[]>("/snowmobile/subpaths/gps"),
      getJson<SnowmobileFraResponse[]>("/snowmobile/fras/gps"),
    ])
      .then(
        (
          responses: [
            SnowmobilePathResponse[],
            SnowmobileSubPathResponse[],
            SnowmobileFraResponse[]
          ]
        ) => {
          const [paths, subPaths, fras] = responses;

          resolve({
            paths,
            subPaths,
            fras,
          });
        }
      )
      .catch((error) => reject(error));
  });
}

/**
 * Fetches all the required data to render pois
 */
export function getPoiData(category?: string): Promise<PoiRequirements> {
  return new Promise<PoiRequirements>((resolve, reject) => {
    Promise.all([
      getJson<PoiResponse[]>(
        category
          ? `/pois?filter={"pointOfInterestCategoryId":${category}"}`
          : "/pois"
      ),
      getJson<PoiCategoryResponse[]>("/pois/categories"),
    ])
      .then((responses: [PoiResponse[], PoiCategoryResponse[]]) => {
        const [pois, categories] = responses;

        resolve({
          pois,
          categories,
        });
      })
      .catch((error) => reject(error));
  });
}
