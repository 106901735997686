import classNames from "classnames";
import { Component } from "react";
import { ReactComponent as HamburgerIcon } from '../../assets/images/hamburger-icon.svg';
import logo from '../../assets/images/logo.png';
import { AppContentContext } from "../../enums/app.enums";
import AppContext from "../context/app-context";
import styles from './header.module.css';

class Header extends Component {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    private previousContentContext?: AppContentContext;

    render() {
        const {appContentContext, menuToggled, setMenuToggled} = this.context;
        const appUrl = process.env.REACT_APP_DOWNLOAD_URL

        return (
            <div className={styles.header}>
                <div className={classNames({
                    [styles.menuToggle]: true,
                    [styles.disabled]: appContentContext === undefined,
                    [styles.toggled]: menuToggled && appContentContext !== undefined,
                })}
                onClick={() => setMenuToggled(!menuToggled)}>
                    <HamburgerIcon />
                </div>

                <div className={styles.logo}>
                    <a target="_blank" rel="noreferrer" href={ appUrl }>
                        <img src={ logo } alt="logo" />
                    </a>
                </div>
            </div>
        );
    }
}

export default Header;