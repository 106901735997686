import React, { Component } from "react";
import AppContext from "../../context/app-context";
import { ReactComponent as DropDownArrow } from "../../../assets/images/down-chevron.svg";
import CategoryListEntry from "../category-list-entry/category-list-entry.component";
import { MapPaths } from "../../../interfaces/map/path.interface";
import {
  MapCategory,
  MapFilter,
} from "../../../interfaces/map/common.interfaces";
import { FeatureType } from "../../../enums/map.enums";
import styles from "./dropdown.module.css";
import { postMessage } from "../../../utils/map.utils";
import { MapSnowmobileSubPaths } from "../../../interfaces/map/snowmobile-path.interface";

type DropdownProps = {};
type DropdownState = {
  isOpen: boolean;
  selectedTitle?: string;
  originalMapFilters?: MapFilter[];
  language?: string;
  defaultTitle?: string;
};
class Dropdown extends Component<DropdownProps, DropdownState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(props: DropdownProps) {
    super(props);

    this.state = {
      isOpen: false,
      selectedTitle: "",
      defaultTitle: "",
      originalMapFilters: [],
      language: undefined,
    };
  }

  componentDidMount(): void {
    const { mapFilters } = this.context;
    const language = window.navigator.language;
    const defaultTitle =
      language && language.includes("en")
        ? "Show all paths"
        : "Visa alla leder";
    this.setState({
      originalMapFilters: mapFilters,
      language: window.navigator.language,
      selectedTitle: defaultTitle,
      defaultTitle: defaultTitle,
    });
  }

  setCategoryPaths = (
    paths: MapPaths,
    snowMobileSubPaths: MapSnowmobileSubPaths,
    category: MapCategory
  ) => {
    const { setFocusedCategoryPaths, setHighlighted, setSelected } =
      this.context;
    const { language } = this.state;
    const title =
      language && language.includes("en")
        ? category.pageTitle_en
        : category.pageTitle;
    const snowMobile = category.id == "2";
    const featureType = snowMobile
      ? FeatureType.SnowmobileSubPath
      : FeatureType.Path;
    const filteredPaths = snowMobile
      ? Object.values(snowMobileSubPaths)
      : Object.values(paths).filter(
          (path) => path.pathCategoryId == category.id
        );

    const mapFilters = [
      ...[{ type: featureType, ids: filteredPaths.map((path) => path.id) }],
    ];

    setTimeout(() => {
      setFocusedCategoryPaths(mapFilters);
      setTimeout(() => {
        setSelected();
        setHighlighted();
        postMessage(JSON.stringify({ deselect: true }));
      });
    });

    this.setState({ selectedTitle: title });
  };

  setAllCategoryPaths = () => {
    const { originalMapFilters, defaultTitle } = this.state;
    const { setFocusedCategoryPaths, setHighlighted, setSelected } =
      this.context;

    setTimeout(() => {
      setFocusedCategoryPaths(originalMapFilters);
      setTimeout(() => {
        setSelected();
        setHighlighted();
        // postMessage(JSON.stringify({ deselect: true }));
      });
    });

    this.setState({ selectedTitle: defaultTitle });
  };

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { mapPathCategories, mapPaths, mapSnowmobileSubPaths } = this.context;
    const { isOpen, selectedTitle, language } = this.state;
    const title = selectedTitle;

    const defaultCategory: MapCategory = {
      id: "0",
      identifier: "default",
      pageTitle: "Visa alla leder",
      pageTitle_en: "Show all paths",
    };
    return (
      <div className={styles.dropdown} onClick={() => this.toggleOpen()}>
        <div className={styles.menuContainer} onClick={() => this.toggleOpen()}>
          <p className={styles.text}> {title}</p>
          <DropDownArrow
            className={isOpen ? styles.chevronOpen : styles.chevron}
          />
        </div>

        <div className={styles.dropdownItems}>
          {isOpen && (
            <div onClick={() => this.toggleOpen()}>
              {Object.values(mapPathCategories).map((category: MapCategory) => (
                <CategoryListEntry
                  category={category}
                  mapPaths={mapPaths}
                  snowMobileSubPaths={mapSnowmobileSubPaths}
                  setCategoryPaths={this.setCategoryPaths}
                  language={language}
                />
              ))}
              <CategoryListEntry
                category={defaultCategory}
                mapPaths={mapPaths}
                snowMobileSubPaths={mapSnowmobileSubPaths}
                setCategoryPaths={this.setAllCategoryPaths}
                language={language}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Dropdown;
