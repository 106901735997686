export const FRESHLY_GROOMED_CUTOFF = 1;

export const COLORS = {
  PATHS: {
    CROSS_COUNTRY_SKIING: "#E42313",
    SNOWMOBILING: "#00589C",
    MOUNTAIN_BIKING: "#00672F",
    HIKING: "#EB6608",
    WINTER_HIKING: "#003323",
    ROMBO: "#882626",
    CULTURES: "#EB6608",
    ENDCAP: "#00513B",
    NO_CATEGORY: "#666666",
    SNOW_MOBILE_FRA: "#96d4b6",
  },
  POIS: {
    NO_CATEGORY: "#F79C0B",
    RESTAURANT: "#BD2C16",
    CAFES: "#7A4F30",
    ATTRACTIONS: "#3A8E52",
    LOOKOUTS: "#67A2C0",
    PARKING: "#00589C",
    CABINS: "#9B907C",
    SKI_BUS: "#00503B",
    STORES: "#F69D12",
    BIKE_WASH: "#016830",
    SNOWMOBILE_RENTAL: "#01599C",
    SPORTS_RENTAL: "#F59907",
    TOILETS: "#01589B",
    SKI_WAXING_SHED: "#9B917D",
    WARNING: "#E42313",
    WEAK_ICE: "#42CBF5",
  },
  PATH_STATUS: {
    OPEN: "#666666",
    CLOSED: "#BD2C16",
    FRESH: "#407B9A",
  },
};
