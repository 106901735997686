import classNames from "classnames";
import React, { Component, RefObject } from "react";
import { FeatureType } from "../../../enums/map.enums";
import { SnowmobileSubPath } from "../../../interfaces/snowmobile-path.interface";
import AppContext from "../../context/app-context";
import SnowmobileSubPathListEntry from "./snowmobile-subpath-list-entry/snowmobile-subpath-list-entry.component";
import styles from './snowmobile-subpath-list.module.css';

class SnowmobilePathList extends Component {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    listRef!: RefObject<HTMLDivElement>;
    entryRefs!: {[key: string]: RefObject<HTMLDivElement>};
    previousSnowmobileSubPaths?: SnowmobileSubPath[];
    previousHighlightedType?: FeatureType;
    previousHighlightedId?: string;

    componentDidMount() {
        this.listRef = React.createRef();
        this.updateRefs();
    }

    componentDidUpdate() {
        const {highlightedType, highlightedId} = this.context;
        this.updateRefs();

        if (highlightedId !== this.previousHighlightedId) {
            this.previousHighlightedId = highlightedId;

            if (highlightedType === FeatureType.SnowmobileSubPath && highlightedId !== undefined) {
                const ref = this.entryRefs[highlightedId];

                if (ref?.current && this.listRef.current) {
                    const entryTop = ref.current.offsetTop;
                    const listTop = this.listRef.current.offsetTop;

                    this.listRef.current.scrollTo({
                        top: entryTop - listTop,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }

    render() {
        const {filteredSnowmobileSubPaths, highlightedType, highlightedId} = this.context;

        // TODO: We should handle this on the backend in the future,
        // but since the paths are mapped into a object we lose any initially provided order.
        // Perhaps we should have a map to look up the index of each ID instead so we'll still have the original array intact.
        const sortedSnowmobileSubPaths = filteredSnowmobileSubPaths.sort((a, b) => {
            // If the status differs, put 'Open' status further up
            if (a.status !== b.status) {
                return b.status > a.status ? 1 : -1;
            }
            // If there's no start or end to A, put it further down the list
            else if (a.start === null && a.end === null) {
                return 1;
            }
            // If there's no start or end to B, put it further down the list
            else if (b.start === null && b.end === null) {
                return -1;
            }

            // Attempt to parse the numeric value of the listed point of each path
            const aNumeric = a.start !== null ? parseInt(a.start!) : parseInt(a.end!);
            const bNumeric = b.start !== null ? parseInt(b.start!) : parseInt(b.end!);
            if (!isNaN(aNumeric) && !isNaN(bNumeric)) {
                return aNumeric === bNumeric 
                    // If the first numeric value of A is the same as the numeric value of B, put the one with a destination further down
                    ? a.end !== null
                        ? 1
                        : -1
                    // If the first numeric values of A and B differ, sort accordingly
                    : aNumeric < bNumeric
                        ? -1
                        : 1;
            }
            // If A is a number but B isn't, put A further up
            else if (!isNaN(aNumeric)) {
                return -1;
            }
            // If B is a number but A isn't, but B further up
            else if (!isNaN(bNumeric)) {
                return 1;
            }

            // Do a string sort if both A and B have either a start or end, otherwise leave untouched
            const aString = a.start !== null ? a.start : a.end;
            const bString = b.start !== null ? b.start : b.end;
            if (aString !== null && bString !== null) {
                return aString.toUpperCase() < bString.toUpperCase() ? -1 : 1;
            }
            return 0;
        });

        return (
            <div className={styles.listContainer} ref={this.listRef}>
                { sortedSnowmobileSubPaths.map((subpath, index) => this.entryRefs && (
                  <div key={subpath.id} ref={this.entryRefs[subpath.id]}>
                    <SnowmobileSubPathListEntry
                        className={classNames({
                            [styles.evenEntry]: index % 2 === 0,
                            [styles.oddEntry]: index % 2 === 1,
                            [styles.highlightedEntry]: highlightedType === FeatureType.SnowmobileSubPath && highlightedId === subpath.id,
                        })}
                        start={subpath.start}
                        end={subpath.end}
                        zone={subpath.zone}
                        status={subpath.status}
                        length={0}
                        groomedAt={subpath.groomedAt}
                        onClick={() => this.handleOnClick(subpath.id)}
                    />
                  </div>
                ))}
                { filteredSnowmobileSubPaths.length === 0 && <div className={styles.empty}>
                    Inga skoterleder hittades
                </div>}
            </div>
        );
    }

    updateRefs() {
        const {filteredSnowmobileSubPaths} = this.context;
        if (JSON.stringify(filteredSnowmobileSubPaths) !== JSON.stringify(this.previousSnowmobileSubPaths)) {
            this.entryRefs = filteredSnowmobileSubPaths.reduce((acc, curr) => ({
                ...acc,
                [curr.id]: React.createRef(), 
            }), {} as {[key: string]: RefObject<HTMLDivElement>});

            this.previousSnowmobileSubPaths = filteredSnowmobileSubPaths;
        }
    }

    handleOnClick(id: string) {
        this.context.setSelected(FeatureType.SnowmobileSubPath, id);
    }
}

export default SnowmobilePathList;