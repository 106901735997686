import classNames from 'classnames';
import {Component} from 'react';
import { COLORS } from '../../../../constants/app.constants';
import { getPathStatusColor, getPathStatusText } from '../../../../utils/rendering.utils';
import { ReactComponent as Icon} from '../../../../assets/images/path-cat-snowmobiling.svg';
import styles from './snowmobile-subpath-list-entry.module.css';

type SnowmobileSubPathListEntryProps = {
    length: number;
    zone: string;
    status: string;
    start: string | null;
    end: string | null;
    groomedAt?: string;
    onClick?: () => void;
} & React.HTMLAttributes<HTMLElement>;

type SnowmobileSubPathListEntryState = {
  //
};

class SnowmobileSubPathListEntry extends Component<SnowmobileSubPathListEntryProps, SnowmobileSubPathListEntryState> {
    render() {
        const {
            zone,
            status,
            groomedAt,
            onClick,
        } = this.props;

        const metadata = zone === 'high' ? 'Högzon' : 'Lågzon';

        const statusText = getPathStatusText(status, groomedAt);
        const statusColor = getPathStatusColor(status, groomedAt);

        const title = this.getTitle();

        return (
            <div className={classNames(
                this.props.className,
                styles.listEntry,
            )} onClick={() => onClick?.()}>
                <div className={styles.container}>
                    <div className={styles.iconContainer}>
                        <Icon className={styles.icon} style={{ color: COLORS.PATHS.SNOWMOBILING }}/>
                    </div>

                    <div className={styles.info}>
                        <div className={styles.infoRow1}>
                            <span className={styles.infoTitle}>{ title }</span>
                        </div>

                        <div className={styles.infoRow2}>
                            <div className={styles.infoMetadata}>
                                <span className={styles.metadataText}>{ metadata }</span>
                                <span className={styles.statusText} style={{ color: statusColor }}>{ statusText }</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getTitle(): string {
        const { start, end } = this.props;
        const points = [start, end].filter(v => v !== null);
        return points.length > 0
            ? `${points.join('-')}`
            : 'Skoterled';
    }
}

export default SnowmobileSubPathListEntry;
