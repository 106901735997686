import classNames from "classnames";
import styles from "./fra-list-entry.module.css";
import { COLORS } from "../../../../constants/app.constants";

import { ReactComponent as Icon } from "../../../../assets/images/path-cat-snowmobiling.svg";

type Props = {
  className: string;
  name: string;
  onClick: () => void;
};

export const FraListEntry = (props: Props) => {
  return (
    <div
      className={classNames(props.className, styles.listEntry)}
      onClick={props.onClick}
    >
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <Icon
            className={styles.icon}
            style={{ color: COLORS.PATHS.SNOWMOBILING }}
          />
        </div>

        <div className={styles.info}>
          <div className={styles.infoRow1}>
            <span className={styles.infoTitle}>{props.name}</span>
          </div>

          <div className={styles.infoRow2}>
            <div className={styles.infoMetadata}>
              <span className={styles.metadataText}>Friåkningsområde</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
