import { COLORS } from "../constants/app.constants";
import { PathCategoryType, PathDifficulty } from "../enums/app.enums";
import { ReactComponent as IconVeryEasy } from '../assets/images/difficulty-very-easy.svg';
import { ReactComponent as IconEasy } from '../assets/images/difficulty-easy.svg';
import { ReactComponent as IconIntermediate } from '../assets/images/difficulty-intermediate.svg';
import { ReactComponent as IconDifficult } from '../assets/images/difficulty-difficult.svg';
import { ReactComponent as IconCrossCountrySkiing} from '../assets/images/path-cross-country-skiing.svg';
import { ReactComponent as IconMountainBiking} from '../assets/images/path-mountain-biking.svg';
import { ReactComponent as IconHiking} from '../assets/images/path-hiking.svg';
import { ReactComponent as IconWinterHiking} from '../assets/images/path-winter-hiking.svg';
import { ReactComponent as IconRombo} from '../assets/images/path-rombo.svg';
import { ReactComponent as IconCulture} from '../assets/images/path-rombo.svg';
import { PathStatus } from "../enums/map.enums";
import moment from "moment";
import { FRESHLY_GROOMED_CUTOFF } from "../constants/app.constants";

enum GroomedState {
  Grooming = 'grooming',
  FreshlyGroomed = 'freshlyGroomed',
  GroomedToday = 'groomedToday',
  GroomedYesterday = 'groomedYesterday',
  GroomedWithinWeek = 'groomedWithinWeek',
  GroomedPastWeek = 'groomedPastWeek',
}

type GroomedStateReturnType = {
  state: string;
  hoursPassed?: number;
  weekday?: number;
};

export function getPathColor(identifier: string): string {
    switch (identifier) {
        case PathCategoryType.CrossCountrySkiing:
            return COLORS.PATHS.CROSS_COUNTRY_SKIING;

        case PathCategoryType.Hiking:
            return COLORS.PATHS.HIKING;
            
        case PathCategoryType.WinterHiking:
          return COLORS.PATHS.WINTER_HIKING;
          
        case PathCategoryType.Rombo:
          return COLORS.PATHS.ROMBO;
          
        case PathCategoryType.Cultures:
          return COLORS.PATHS.CULTURES;

        case PathCategoryType.MountainBiking:
            return COLORS.PATHS.MOUNTAIN_BIKING;

        default:
            return COLORS.PATHS.NO_CATEGORY;
    }
}

export function getPathDifficultyIcon(difficulty: string, props?: any): JSX.Element {
  switch (difficulty) {
    case PathDifficulty.VeryEasy:
      return <IconVeryEasy {...props} />;

    case PathDifficulty.Easy:
      return <IconEasy {...props} />;

    case PathDifficulty.Medium:
      return <IconIntermediate {...props} />;

    case PathDifficulty.Hard:
      return <IconDifficult {...props} />;

    default:
      return <div {...props} ></div>;
  }
}

// TODO: Implement localization similar to application?
export function getPathDifficultyName(difficulty: string, plural: boolean): string {
  switch (difficulty) {
    case PathDifficulty.VeryEasy:
      return plural ? 'Mycket lätta' : 'Mycket lätt';

    case PathDifficulty.Easy:
      return plural ? 'Lätta' : 'Lätt';

    case PathDifficulty.Medium:
      return plural ? 'Medelsvåra' : 'Medelsvår';

    case PathDifficulty.Hard:
      return plural ? 'Svåra' : 'Svår';

    default:
      return '';
  }
}

export function getPartialPathIcon(identifier: string, props?: any): JSX.Element {
  switch (identifier) {
    case PathCategoryType.CrossCountrySkiing:
      return <IconCrossCountrySkiing {...props} />;

    case PathCategoryType.Hiking:
      return <IconHiking {...props} />;

    case PathCategoryType.MountainBiking:
      return <IconMountainBiking {...props} />;
      
    case PathCategoryType.WinterHiking:
      return <IconWinterHiking {...props} />;

    case PathCategoryType.Rombo:
      return <IconRombo {...props} />;

    case PathCategoryType.Cultures:
      return <IconCulture {...props} />;

    default:
      return <div {...props}></div>;
  }
}

// TODO: Implement localization similar to application?
export function getPathStatusText(status: string, groomedAt?: string | null) {
  switch (status) {
    case PathStatus.Open:
      return '';

    case PathStatus.Closed:
      return 'Stängt';

    default:
      return '';
  }
}

export function getPathStatusColor(status: string, groomedAt?: string | null): string {
  switch (status) {
    case PathStatus.Open:
      if (groomedAt) {
        const groomedState = getPathGroomedState(groomedAt);

        if (
          groomedState.state === GroomedState.Grooming ||
          groomedState.state === GroomedState.FreshlyGroomed ||
          groomedState.state === GroomedState.GroomedToday
        ) {
          return COLORS.PATH_STATUS.FRESH;
        }
      }
      return COLORS.PATH_STATUS.OPEN;

    case PathStatus.Closed:
      return COLORS.PATH_STATUS.CLOSED;

    default:
      return COLORS.PATH_STATUS.OPEN;
  }
}

export function getPathGroomedState(groomedAt: string): GroomedStateReturnType {
  const now = moment();
  const date = moment(groomedAt);

  const timePassed = moment.duration(now.diff(date));
  const hoursPassed = Math.floor(timePassed.asHours());
  const weekday = date.day();

  if (hoursPassed < FRESHLY_GROOMED_CUTOFF) {
    return {state: GroomedState.Grooming};
  }
  if (date.isSame(now.clone().startOf('day'), 'd')) {
    return {state: GroomedState.GroomedToday, hoursPassed, weekday};
  }

  if (date.isSame(now.clone().subtract(1, 'days').startOf('day'), 'd')) {
    return {state: GroomedState.GroomedYesterday, hoursPassed, weekday};
  }

  // NOTE: Not to be included right now, keeping functionality just in case
  /*
  if (date.isAfter(now.clone().subtract(7, 'days').startOf('day'), 'd')) {
    return {state: GroomedState.GroomedWithinWeek, hoursPassed, weekday};
  }*/

  return {state: GroomedState.GroomedPastWeek, hoursPassed, weekday};
}