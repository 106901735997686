import { Component } from "react";
import AppContext from "../context/app-context";
import Dropdown from "./dropdown/dropdown.component";
import styles from "./map-category-menu.module.css";

type MapCategoryMenuProps = {};
type MapCategoryMenuState = {};
class MapCategoryMenu extends Component<
  MapCategoryMenuProps,
  MapCategoryMenuState
> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(props: MapCategoryMenuProps) {
    super(props);
  }

  render() {
    return (
      <div className={styles.categoryMenu}>
        <Dropdown />
      </div>
    );
  }
}

export default MapCategoryMenu;
