import classNames from "classnames";
import { Component } from "react";
import { AppContentContext, FilterIdentifier } from "../../../enums/app.enums";
import AppContext from "../../context/app-context";
import { ReactComponent as ArrowIcon } from '../../../assets/images/dropdown-arrow.svg';
import styles from './filters.module.css';
import { passesAreaFilter, passesDiffidultyFilter, passesLengthFilter, passesStatusFilter } from "../../../utils/filter.utils";
import { FeatureType } from "../../../enums/map.enums";
import { MapFilter } from "../../../interfaces/map/common.interfaces";

type FilterProps = {
    excludedIdentifiers?: FilterIdentifier[],
};

type FilterState = {
    activeFilterIdentifier?: FilterIdentifier;
    previousFilterIdentifier?: FilterIdentifier;
};

class Filters extends Component<FilterProps, FilterState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: FilterProps) {
        super(props);

        this.state = {
            activeFilterIdentifier: undefined,
            previousFilterIdentifier: undefined,
        };
    }

    render() {
        const {listFilters, activeListFilterIndexes} = this.context;
        const {activeFilterIdentifier, previousFilterIdentifier} = this.state;
        const {excludedIdentifiers} = this.props;

        const listedFilterIndex = listFilters.findIndex(filter => activeFilterIdentifier !== undefined
            ? filter.identifier === activeFilterIdentifier
            : filter.identifier === previousFilterIdentifier
        );

        const listedFilter = listFilters[listedFilterIndex];
        const ListedFilterOptionIndex = activeListFilterIndexes[listedFilterIndex];

        return (
            <div className={classNames({
                [styles.container]: true,
                [styles.toggled]: activeFilterIdentifier !== undefined,
            })}>
                <div className={styles.filters}>
                    { listFilters.map((filter, index) => (
                        (!excludedIdentifiers || !excludedIdentifiers.includes(filter.identifier)) &&
                        <div key={filter.identifier} 
                            className={classNames({
                                [styles.filter]: true,
                                [styles.active]: activeFilterIdentifier === filter.identifier,
                            })} onClick={() => this.handleFilterToggle(filter.identifier)}>
                            <span className={styles.filterText}>
                                { this.getFilterName(index) }
                            </span>

                            <ArrowIcon className={styles.arrowIcon} />
                        </div>
                    ))}
                </div>
                <div className={styles.optionsContainer}>
                    <div className={styles.options}>
                        { listedFilter?.options.map((option, index) => (
                            <span key={option.name} 
                                className={classNames({
                                    [styles.option]: true,
                                    [styles.active]: ListedFilterOptionIndex === index,
                                })} onClick={() => this.handleFilterOption(listedFilterIndex, index)}>
                                
                                { option.icon && <div className={styles.optionIcon}>
                                    { option.icon }
                                </div> }
                                { option.name }
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    getFilterName(index: number): string {
        const {listFilters, activeListFilterIndexes} = this.context;

        const filter = listFilters[index];
        const option = filter?.options[activeListFilterIndexes[index] || 0];

        const name = option.value === undefined
            ? filter.placeholder
            : option.name;

        return name.length > 11
            ? `${name.substr(0, 11)}..`
            : name;
    }

    handleFilterToggle(identifier: FilterIdentifier) {
        const {activeFilterIdentifier} = this.state;
        const previousFilterIdentifier = activeFilterIdentifier;
        const newActiveFilter = identifier === activeFilterIdentifier 
            ? undefined 
            : identifier;

        this.setState({
            ...this.state,
            previousFilterIdentifier,
            activeFilterIdentifier: newActiveFilter,
        });
    }

    handleFilterOption(filterIndex: number, optionIndex: number) {
        const {activeListFilterIndexes, setActiveListFilterIndexes} = this.context;

        setActiveListFilterIndexes(
            activeListFilterIndexes.map((curr, index) => index === filterIndex
                ? optionIndex
                : curr
            ),
        );

        // Both this and setActiveListFilterIndexes modify app state, delay one frame so we don't override state
        setTimeout(() => {
            this.handleNewFilter();
        });
    }

    handleNewFilter() {
        const {paths, snowmobileSubPaths, appContentContext, listFilters, activeListFilterIndexes, setFilteredPaths, setFilteredSnowmobileSubPaths, setMapFilters, setSelected} = this.context;
        const selections: {[key: string]: any} = listFilters.reduce((acc, curr, index) => ({
            ...acc,
            [curr.identifier]: curr.options[activeListFilterIndexes[index]].value,
        }), {});

        let mapFilters: MapFilter[] = [];
        let focusFeatureType: FeatureType;
        switch(appContentContext) {
            case AppContentContext.CrossCountrySkiing:
            case AppContentContext.MountainBiking:
            case AppContentContext.Hiking:
            case AppContentContext.WinterHiking:
            case AppContentContext.Rombo:
            case AppContentContext.Cultures:
                const filteredPaths = Object.values(paths).filter(path => 
                    passesLengthFilter(path.length, selections[FilterIdentifier.Length]) &&
                    passesStatusFilter(path.status, path.groomedAt, selections[FilterIdentifier.Status]) &&
                    passesDiffidultyFilter(path.difficulty, selections[FilterIdentifier.Difficulty]) &&
                    passesAreaFilter(path.areas, selections[FilterIdentifier.Area])
                );
                setFilteredPaths(filteredPaths);

                mapFilters = [
                    ...(filteredPaths.length > 0 ? [{type: FeatureType.Path, ids: filteredPaths.map(path => path.id)}] : []),
                    {type: FeatureType.Poi, ids: []},
                ];
                focusFeatureType = FeatureType.Path;
                break;

            case AppContentContext.Snowmobiling:
                const filteredSnowmobileSubPaths = Object.values(snowmobileSubPaths).filter(subPath => 
                    passesStatusFilter(subPath.status, subPath.groomedAt, selections[FilterIdentifier.Status]) &&
                    passesAreaFilter(subPath.areas, selections[FilterIdentifier.Area])
                );
                setFilteredSnowmobileSubPaths(filteredSnowmobileSubPaths);

                mapFilters = [
                    ...(filteredSnowmobileSubPaths.length > 0 ? [{type: FeatureType.SnowmobileSubPath, ids: filteredSnowmobileSubPaths.map(subPath => subPath.id)}] : []),
                    {type: FeatureType.Poi, ids: []},
                ];
                focusFeatureType = FeatureType.SnowmobileSubPath;
                break;

            default:
                break;
        }

        // Focus on the selected type, then filter them 
        setTimeout(() => {
            setSelected(focusFeatureType);
            setTimeout(() => {
                setMapFilters(mapFilters);
            });
        });
    }
}

export default Filters;