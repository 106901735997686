import { Component } from "react";
import styles from "./App.module.css";

import MapView from "./components/map-view/map-view.component";
import MapButtons from "./components/map-buttons/map-buttons.component";
import { setRenderScale } from "./utils/map.utils";
import Header from "./components/header/header.component";
import AppProvider from "./components/context/app-provider";
import MapOverlay from "./components/map-overlay/map-overlay.component";
import moment from "moment-timezone";
import MapCategoryMenu from "./components/map-category-menu/map-category-menu.component";
import PathInfo from "./components/path-info/path-info.component";
import AppContext from "./components/context/app-context";

type AppProps = {
  //
};

class App extends Component<AppProps> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  constructor(props: AppProps) {
    super(props);

    if (process.env.REACT_APP_STANDALONE === "true") {
      // NOTE: .standalone is defined in the index css rules
      document.documentElement.classList.add("standalone");
      setRenderScale(0.7);
    }
  }

  componentDidMount() {
    moment.tz.setDefault("Europe/Stockholm");
  }

  render() {
    const isStandalone = process.env.REACT_APP_STANDALONE === "true";

    return (
      <AppProvider>
        <div className={styles.app}>
          {isStandalone && <Header />}

          <div className={styles.mapContainer}>
            <MapView />

            <PathInfo isStandAlone={isStandalone} />

            {!isStandalone && (
              <div className={styles.buttonContainer}>
                <MapCategoryMenu />
                <MapButtons />
              </div>
            )}

            {isStandalone && <MapOverlay />}
          </div>
        </div>
      </AppProvider>
    );
  }
}

export default App;
