import classNames from 'classnames';
import { Component } from 'react';
import { PathCategoryType } from '../../../../enums/app.enums';
import { toKilometerString } from '../../../../utils/conversion.utils';
import { getPathColor, getPathDifficultyIcon, getPathDifficultyName, getPartialPathIcon, getPathStatusColor, getPathStatusText } from '../../../../utils/rendering.utils';
import styles from './path-list-entry.module.css';

type PathListEntryProps = {
    type: string;
    name: string;
    number: string;
    length: number;
    tags?: any[];
    status: string;
    difficulty: string;
    groomedAt?: string;
    onClick?: () => void;
} & React.HTMLAttributes<HTMLElement>;

type PathListEntryState = {
    //
};

class PathListEntry extends Component<PathListEntryProps, PathListEntryState> {
    render() {
        const {
            type,
            name,
            number,
            length,
            tags,
            difficulty,
            status,
            groomedAt,
            onClick,
        } = this.props;

        const color = getPathColor(type);
        const Icon = (props: any) => getPartialPathIcon(type, props);
        const DifficultyIcon = (props: any) => getPathDifficultyIcon(difficulty, props);

        const difficultyName = getPathDifficultyName(
            difficulty,
            false,
        );
        const metadata =
            tags && tags.length
                ? `${difficultyName}, ${tags
                    .join(', ')}`
                : difficultyName;

        let statusText = (
            type !== PathCategoryType.MountainBiking && 
            type !== PathCategoryType.Hiking && 
            type !== PathCategoryType.WinterHiking && 
            type !== PathCategoryType.Rombo && 
            type !== PathCategoryType.Cultures)
            ? getPathStatusText(status, groomedAt)
            : getPathStatusText(status);
        const statusColor = (
            type !== PathCategoryType.MountainBiking && 
            type !== PathCategoryType.Hiking && 
            type !== PathCategoryType.WinterHiking && 
            type !== PathCategoryType.Rombo && 
            type !== PathCategoryType.Cultures)
            ? getPathStatusColor(status, groomedAt)
            : getPathStatusColor(status);

        for (const tag in tags) {
            let values = Object.values(tags[parseInt(tag)]);
            if (values.includes("Prepareras ej")) {
                statusText = "";
            }
        }
        
        const renderIcon = () => (
            <>
                <Icon className={styles.icon} style={{ color }} />
                {type !== 'CATEGORY_6' && (
                  <span className={styles.iconText}>{number}</span>
              )}
           </>
        );
          
          
        
        return (
            <div className={classNames(
                this.props.className,
                styles.listEntry,
            )} onClick={() => onClick?.()}>
                <div className={styles.container}>
                    <div className={styles.iconContainer}>
                        {renderIcon()}
                    </div>

                    <div className={styles.info}>
                        <div className={styles.infoRow1}>
                            <span className={styles.infoTitle}>{name}</span>
                            <span className={styles.infoLength}>{toKilometerString(length)}</span>
                        </div>

                        <div className={styles.infoRow2}>
                            <div className={styles.infoMetadata}>
                                <DifficultyIcon className={styles.difficultyIcon} />
                                <span className={styles.metadataText}>{metadata}</span>
                                <span className={styles.statusText} style={{ color: statusColor }}>{statusText}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PathListEntry;
