



import React, { Component } from "react";
import { MapCategory } from "../../../interfaces/map/common.interfaces";
import { MapPaths } from "../../../interfaces/map/path.interface";
import { ReactComponent as SettingsIcon } from '../../../assets/images/map-settings.svg';
import styles from './category-list-entry.module.css';
import {
    getIconFromIdentifier
} from '../../../utils/map.utils';
import { MapSnowmobileSubPaths } from "../../../interfaces/map/snowmobile-path.interface";

type CategoryListEntryProps = {
    category: MapCategory;
    mapPaths: MapPaths;
    setCategoryPaths: (mapPaths: MapPaths, snowMobileSubPaths: MapSnowmobileSubPaths, mapCategory: MapCategory) => void;
    language?: string;
    snowMobileSubPaths: MapSnowmobileSubPaths;

}
type CategoryListEntryState = {

}

class CategoryListEntry extends Component<CategoryListEntryProps, CategoryListEntryState> {


    constructor(props: CategoryListEntryProps) {
        super(props);
    }

    render() {

        const { category, mapPaths, snowMobileSubPaths, language, setCategoryPaths } = this.props;
        const title = language && language?.includes("en") ? category.pageTitle_en : category.pageTitle;
        const iconSource = getIconFromIdentifier(category.identifier);
        return (
            <div>
                <button className={styles.entry} onClick={() => setCategoryPaths(mapPaths, snowMobileSubPaths, category)}>
                    <p className={styles.entryTitle}>{title}</p>
                    {iconSource && (
                    <img className={styles.icon} src={iconSource}></img>
                    )}
                </button>
            </div>
        )
    }
    
}


export default CategoryListEntry;