import classNames from "classnames";
import { Component } from "react";
import { AppContentContext } from "../../enums/app.enums";
import AppContext from "../context/app-context";
import SideBar from "../side-bar/side-bar.component";
import styles from './map-overlay.module.css';

class MapOverlay extends Component {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    render() {
        const { menuToggled, appContentContext } = this.context;

        const contentText = this.getContentText();

        return (
            <div className={styles.mapOverlay}>
                <div className={classNames({
                  [styles.menuTransform]: true,
                  [styles.toggled]: menuToggled && appContentContext !== undefined,
                })}>
                  <div className={styles.menuContainer}>
                    <SideBar />
                  </div>
                </div>

                { contentText && <div className={styles.gradient}>
                    <span>{ contentText }</span>
                </div> }
              </div>
        );
    }

    getContentText(): string {
      const {filteredPaths, filteredSnowmobileSubPaths, appContentContext} = this.context;

      switch(appContentContext) {
        case AppContentContext.Snowmobiling:
          return `${filteredSnowmobileSubPaths.length} Skoterleder`;

        case AppContentContext.CrossCountrySkiing:
          return `${filteredPaths.length} Längdskidspår`;

        case AppContentContext.MountainBiking:
          return `${filteredPaths.length} Cykelleder`;

        case AppContentContext.Hiking:
          return `${filteredPaths.length} Vandringsspår`;
          
        case AppContentContext.WinterHiking:
          return `${filteredPaths.length} Vintervandrigsspår`;

        case AppContentContext.Rombo:
          return `${filteredPaths.length} Romboleden`;

        case AppContentContext.Cultures:
          return `${filteredPaths.length} Kulturleder`;
        

        default:
          return '';
      }
    }
}

export default MapOverlay;